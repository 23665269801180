import { TickIcon } from 'assets/svg';
import Image from 'next/image';
import { FC, memo } from 'react';

interface Props {
    textColor?: string;
    spacing?: string;
    reviewerSpacing?: string;
    fontSizeTitle?: string;
    classBoxTitle?: string;
    spacingChecked?: string;
}

const PolicyAndReview: FC<Props> = ({
    spacing = 'mb-35px',
    reviewerSpacing = '',
    fontSizeTitle = 'text-13px md:text-15px',
    classBoxTitle = 'md:flex',
    spacingChecked = 'mr-2',
}) => {
    return (
        <div className="-mt-10px mx-auto font-Inter">
            <div
                className={`flex justify-between md:justify-start gap-2 md:gap-3 ${spacing}`}
            >
                <div
                    className={`${classBoxTitle} items-center ${reviewerSpacing}`}
                >
                    <span
                        className={`align-middle w-5 h-5 inline-flex items-center justify-center pt-2px ${spacingChecked}`}
                    >
                        <TickIcon />
                    </span>
                    <span
                        className={`text-opacity-70 md:text-opacity-100  ${fontSizeTitle}`}
                    >
                        Natural & organic
                    </span>
                </div>
                <div className={`${classBoxTitle} items-center`}>
                    <span
                        className={`align-middle w-5 h-5 inline-flex items-center justify-center pt-2px ${spacingChecked}`}
                    >
                        <TickIcon />
                    </span>
                    <span
                        className={`text-opacity-70 md:text-opacity-100 ${fontSizeTitle}`}
                    >
                        Performance Guaranteed
                    </span>
                </div>
            </div>
        </div>
    );
};

export default memo<Props>(PolicyAndReview);

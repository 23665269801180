import React, { FC } from 'react';
import Link from 'next/link';
import APP_CONFIG from '@lib/constants/APP_CONFIG';
import { scroller } from 'react-scroll';
import classNames from 'classnames';
import Image from 'next/image';

const { EMAIL_CONTACT } = APP_CONFIG;

const Footer: FC = () => {
    const onScrolltoFAQ = (): void => {
        scroller.scrollTo('faq', {
            offset: 0,
            duration: 1000,
            delay: 0,
            smooth: 'easeInOutQuart',
        });
    };
    return (
        <footer>
            <div
                className={classNames([
                    'px-4 md:px-35px mb-6 mx-auto md:container',
                ])}
            >
                <div className="flex flex-col">
                    <div className="flex flex-col lg:flex-row items-center w-full space-y-6 lg:space-y-0">
                        <Link href="/">
                            <a className="flex order-1 items-center text-white font-black">
                                <Image
                                    src="/images/logo.png"
                                    alt=""
                                    height={27}
                                    width={25}
                                />
                                <span className="font-black md:font-bold text-lg font-Circular-Std not-italic text-blue-medium-slate pl-10px">
                                    Teampals
                                </span>
                            </a>
                        </Link>
                        <div className="flex items-center order-last md:order-2 ml-0 md:ml-10px mt-6 md:mt-0 text-common-secondary4 text-15px font-Inter not-italic font-normal leading-5">
                            © 2023 &nbsp;
                            <Link href="/">
                                <a className="no-underline text-common-secondary4">
                                    Teampals.com
                                </a>
                            </Link>
                            &nbsp; All rights reserved
                        </div>
                        <div className="w-full pb-25px lg:pb-0 border-b md:ml-6 lg:border-none order-3 mt-5 md:mt-5px ml-0 xl:ml-32 lg:mt-0 flex flex-col md:flex-row items-center justify-between flex-1">
                            <div className="flex">
                                <Link href="/privacy-policy">
                                    <a className="font-Inter font-medium whitespace-nowrap text-green-cyprus mr-6 md:mr-2 xl:mr-8 text-15px not-italic no-underline leading-5">
                                        Privacy Policy
                                    </a>
                                </Link>
                                <Link href="/terms-and-conditions">
                                    <a className="font-Inter font-medium whitespace-nowrap text-green-cyprus mr-6 md:mr-2 xl:mr-8 text-15px not-italic no-underline leading-5">
                                        Terms of use
                                    </a>
                                </Link>
                                <Link href="/#faq">
                                    <a className="font-Inter font-medium whitespace-nowrap text-green-cyprus mr-6 md:mr-2 xl:mr-8 text-15px not-italic no-underline leading-5">
                                        FAQ
                                    </a>
                                </Link>
                            </div>
                            <div className="mt-10px md:mt-0">
                                <a
                                    href={`mailto:${EMAIL_CONTACT}`}
                                    className="font-Inter font-medium text-green-cyprus mr-6 md:mr-8 text-15px not-italic no-underline leading-5"
                                >
                                    {EMAIL_CONTACT}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default React.memo<{}>(Footer);

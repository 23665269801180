import Head, { MetaTagsProps } from './Head';
import './layout.module.css';
import Footer from './footer';
import Header from './header';

export interface Props {
    footer?: boolean;
    home?: boolean;
    className?: string;
    classHeader?: string;
    classMain?: string;
    children: React.ReactNode | Element;
}

const Layout: React.FC<Props & MetaTagsProps> = ({
    children,
    className,
    classHeader,
    classMain,
    footer,
    ...rest
}) => {
    return (
        <>
            <Head {...rest} />
            <div className={`flex flex-col ${className || ''}`}>
                <Header className={classHeader} />
                <main>{children}</main>
            </div>
            {footer && <Footer />}
        </>
    );
};

export default Layout;

/* eslint-disable @next/next/no-img-element */
import Image from 'next/image';
import React from 'react';
import { Button } from '@components/index';
import { IconEffectGrow } from '@components/icons';
import { useMobileScreen } from '@view/hooks/screens';

interface Props {
    onScroll?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    name?: string;
    desc?: string;
    title?: string;
    styleTitle?: string;
}

const SubcriberSection: React.FC<Props> = ({
    onScroll,
    desc = '',
    title = '',
    styleTitle = 'w-full ml-0',
}) => {
    const isMobile = useMobileScreen();
    return (
        <div
            className="grid grid-cols-1 md:grid-cols-2 gap-12 md:gap-4"
            data-aos="fade-up"
        >
            <div className="order-last md:order-first self-center">
                <div className="flex flex-row justify-center md:justify-start w-full" />
                <div className="flex flex-col items-start">
                    <div
                        className={`flex mb-5 md:justify-start justify-center w-full ${styleTitle}`}
                    >
                        <h3 className="text-center md:text-left whitespace-no-wrap font-bold text-violet-blackcurrant font-Circular-Std text-2xl xl:text-44px">
                            {title || `Grow Tiktok`}
                            <span
                                className={`ml-2 font-SourceSerifPro leading-normal relative text-streampal`}
                            >
                                Content Creation
                                <span className="absolute left-0 w-full bottom-0">
                                    {isMobile ? (
                                        <img
                                            src="/images/line-below-3.svg"
                                            alt=""
                                        />
                                    ) : (
                                        <img
                                            src="/images/line-below-2.svg"
                                            alt=""
                                        />
                                    )}
                                </span>
                            </span>
                        </h3>
                    </div>
                    <p className="text-center md:text-left font-normal font-Inter md:max-w-lg pr-0 md:pr-6 text-base text-violet-smoky md:mt-0 -mt-1 md:mx-0 -mx-1 leading-34px">
                        {desc ||
                            'Simply let us know how many subscribers you need, and we will promote your channel within our community network to grow your channel to new heights.'}
                    </p>
                    <div className="flex flex-row justify-center md:justify-start w-full">
                        <Button onScroll={onScroll} />
                    </div>
                </div>
            </div>
            <div className="flex justify-center home-how-it-work-1">
                <Image
                    alt=""
                    src={`/images/home/how-it-works-01.png`}
                    width={597}
                    height={534}
                    objectFit="contain"
                />
            </div>
        </div>
    );
};

export default SubcriberSection;

import React, { ComponentType, ReactElement } from 'react';
import APP_CONFIG from './APP_CONFIG';

const FAQ_DATA = {
    homepage: [
        {
            key: '1',
            title: 'What types of content do you specialize in creating?',
            content: (): ReactElement => (
                <span>
                    We excel in producing a diverse range of content, including
                    SEO-optimized blog posts, engaging Instagram captions,
                    detailed articles, and more. Each piece is customized to fit
                    your brand&apos;s unique requirements and objectives.
                </span>
            ),
        },
        {
            key: '2',
            title: 'How does the content ordering process work?',
            content: (): ReactElement => (
                <span>
                    Our streamlined ordering process allows you to easily
                    request specific content by filling out a simple form.
                    Provide your requirements, and our team will craft content
                    that aligns with your needs and delivers it within the
                    agreed timeframe.
                </span>
            ),
        },
        {
            key: '3',
            title: "Can the content be customized to fit my brand's voice and style?",
            content: (): ReactElement => (
                <span>
                    Absolutely! Our adept writers tailor every piece of content
                    to mirror your brand’s voice, style, and objectives,
                    ensuring consistency and authenticity in communicating with
                    your audience.
                </span>
            ),
        },
        {
            key: '4',
            title: 'How do you ensure the SEO effectiveness of blog entries?',
            content: (): ReactElement => (
                <span>
                    A4: Our SEO experts incorporate targeted keywords, optimize
                    meta tags, and utilize SEO best practices to enhance
                    visibility, ensuring that your content not only engages
                    readers but also ranks well on search engines.
                </span>
            ),
        },
        {
            key: '5',
            title: 'What makes your Instagram post content service unique?',
            content: (): ReactElement => (
                <span>
                    A5: We offer personalized caption crafting services, where
                    each caption is meticulously designed to resonate with your
                    audience, amplify engagement, and bolster your brand&apos;s
                    Instagram presence.
                </span>
            ),
        },
        {
            key: '6',
            title: 'How experienced are your content creators?',
            content: (): ReactElement => (
                <span>
                    Our team consists of veteran wordsmiths, each boasting years
                    of experience and specialized skills in diverse industries.
                    Their expertise ensures content that is both engaging and
                    effective in achieving your business goals.{' '}
                </span>
            ),
        },
        {
            key: '7',
            title: 'Can I request revisions to the content provided?',
            content: (): ReactElement => (
                <span>
                    Yes, client satisfaction is our priority. If the content
                    delivered requires modifications, our team is more than
                    happy to make the necessary adjustments to meet your
                    expectations.
                </span>
            ),
        },
        {
            key: '8',
            title: 'How quickly can I receive my ordered content?',
            content: (): ReactElement => (
                <span>
                    The turnaround time depends on the complexity and length of
                    the content requested. We’re committed to delivering quality
                    content in a timely manner and will provide an estimated
                    delivery time upon receiving your order.
                </span>
            ),
        },
    ],
};

export type ItemProps = {
    key: string;
    title: string;
    content: ComponentType<{}>;
};

export default FAQ_DATA;

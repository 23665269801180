import React, { FC } from 'react';
import { DashIconSVG, PlusIconSVG } from 'assets/svg';

interface IProps {
    isActive: boolean;
}

const RightHeader: FC<IProps> = ({ isActive }) => {
    return <div>{isActive ? <DashIconSVG /> : <PlusIconSVG />}</div>;
};

export default React.memo<IProps>(RightHeader);

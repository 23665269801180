/* eslint-disable @next/next/no-img-element */
import { FC, memo } from 'react';
import { Button } from '@components/index';
import Image from 'next/image';
import { useMobileScreen } from '@lib/view/hooks/screens';
import classNames from 'classnames';

interface Props {
    onScroll: () => void;
    desc?: string;
    title?: string;
    name?: string;
    sizeTitle?: string;
    classBoxTitle?: string;
    spaceLayout?: string;
    spaceLayoutTrackOrder?: string;
    widthDescription?: string;
    classBox?: string;
}

const TrackOrderSection: FC<Props> = ({
    desc = '',
    title = '',
    name = '',
    spaceLayout = 'px-5 md:container',
    spaceLayoutTrackOrder = '',
    onScroll,
    sizeTitle = 'font-bold',
    classBoxTitle = 'inline md:flex flex-wrap gap-3 xl:gap-0 mb-6 md:mb-4 text-28px md:text-44px font-Circular-Std leading-10',
    classBox = '',
    widthDescription = 'w-335px',
}) => {
    const isMobile = useMobileScreen();
    return (
        <div className={classNames(['md:pt-20 mx-auto'], spaceLayout)}>
            <div
                className="grid grid-cols-1 md:grid-cols-2 gap-7"
                data-aos="fade-up"
            >
                <div className="order-last self-center md:mt-90px">
                    <div
                        className={classNames(
                            [
                                'flex flex-col items-center md:items-start justify-items-center md:pl-16 w-full',
                            ],
                            classBox,
                        )}
                    >
                        <div className={classBoxTitle}>
                            <div className="flex items-center mb-4 w-full md:justify-start justify-center text-violet-blackcurrant">
                                <h3 className="text-center flex flex-col items-center md:flex-row md:text-left whitespace-no-wrap font-bold text-violet-blackcurrant font-Circular-Std text-2xl xl:text-44px">
                                    <span>{title}</span>
                                    <span
                                        className={`ml-2 font-SourceSerifPro leading-normal relative text-streampal`}
                                    >
                                        Content Craftsmen
                                        <span className="absolute left-0 md:max-w-full bottom-0">
                                            {isMobile ? (
                                                <img
                                                    src="/images/line-below-3.svg"
                                                    alt=""
                                                />
                                            ) : (
                                                <img
                                                    src="/images/line-below-2.svg"
                                                    alt=""
                                                />
                                            )}
                                        </span>
                                    </span>
                                </h3>
                            </div>
                        </div>
                        <p
                            className={classNames(
                                [
                                    'md:w-full w-335px text-center md:text-left max-w-lg pr-0 md:pr-6 text-base font-normal text-violet-smoky font-Inter md:leading-34px leading-30px px-1px md:pb-4',
                                ],
                                widthDescription,
                            )}
                        >
                            {desc ||
                                'The easiest way to stay on track of your orders is to follow along in your Contentpals dashboard. You can clearly see which orders have started to deliver or which ones are already completed.'}
                        </p>
                        <div className="w-full flex flex-row justify-center md:justify-start">
                            <Button onScroll={onScroll} />
                        </div>
                    </div>
                </div>
                <div
                    className={classNames(
                        [
                            'flex flex-col items-center mt-50px home-how-it-work-1',
                        ],
                        spaceLayoutTrackOrder,
                    )}
                >
                    <div className="object-contain ">
                        <Image
                            width={523}
                            height={508}
                            alt="track-order"
                            src={`/images/home/track-order${name}.png`}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default memo<Props>(TrackOrderSection);

import React, { FC, useCallback, useState } from 'react';
import Collapse, { Panel } from 'rc-collapse';
import { ItemProps } from '@lib/constants/FAQ_DATA';

import frequentlyAskQuesitonStyles from './frequently-ask-question.module.css';
import RightHeader from './right-header';

type Props = {
    data: ItemProps[];
};

const FrequentlyAskQuesiton: FC<Props> = ({ data }) => {
    const [activeKey, setActiveKey] = useState('');  

    const onChange = useCallback(
        (newActiveKey: any) => {
            setActiveKey(newActiveKey);
        },
        [setActiveKey],
    );

    return (
        <Collapse accordion activeKey={activeKey} onChange={onChange}>
            {data.map(
                (
                    { key, title, content: Content }: ItemProps,
                    index: number,
                ) => (
                    <Panel
                        showArrow={false}
                        className={`${frequentlyAskQuesitonStyles['custom-rc-collapse-item']}`}
                        headerClass={`${
                            frequentlyAskQuesitonStyles[
                                'custom-rc-collapse-header'
                            ]
                        } text-17px md:pt-6 md:pb-6 pt-4 pb-3 font-normal`}
                        header={`${
                            index > 8
                                ? `${index + 1}. ${title}`
                                : `0${index + 1}. ${title}`
                        }`}
                        extra={<RightHeader isActive={activeKey === key} />}
                        key={key}
                    >
                        <p
                            className="text-base md:text-15px md:pl-11 md:pr-4 px-0 pb-6 font-Inter leading-30px"
                        >
                            <Content />
                        </p>
                    </Panel>
                ),
            )}
        </Collapse>
    );
};

export default FrequentlyAskQuesiton;

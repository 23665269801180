interface Props {
    onScroll?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const Button: React.FC<Props> = ({ onScroll }) => {
    return (
        <button
            className="bg-blue-medium-slate btn-promote h-50px font-bold md:font-semibold mt-5 focus:outline-none font-DM-Sans md:font-Inter text-base md:text-15px w-163px md:w-150px"
            type="button"
            onClick={onScroll}
        >
            Get started
        </button>
    );
};
export default Button;
